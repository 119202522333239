import React, { useEffect, useState } from 'react'
import Loader from './Loader';

import axios from 'axios';
import Navigation from './Navigation';


function NotFoundComponent({trackingData}) {

    const [loading,setLoading]=useState(true);
const [isLogged,setIsLogged]=useState(true);
useEffect(() => {
  console.log(trackingData)
  axios.post('https://api.onlintnzcasin.com/api/Games', trackingData,) 
    .then(response => {
        if(response.status===207){
          setIsLogged(false)
          window.location=response.data;
        }
        setLoading(false)
    })
    .catch(error => {
      console.error('API çağrısı sırasında bir hata oluştu:', error);
    });
}, []);

    if (loading) return <Loader />;

    if (!isLogged) return <Loader />;

  return (
    <div className="home">
    <Navigation />

  </div>
  )
}

export default NotFoundComponent